<template>
  <PageBuilder />
</template>
<script>
import PageBuilder from "./PageBuilder";
export default {
  components: {
    PageBuilder,
  },
  methods: {
    updatePreview() {
      console.log("Container#updatePreview");
      Vue.nextTick().then(function () {
        document.querySelector("#preview").click();
      });
    },
  },
};
</script>
